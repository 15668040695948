import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/applicants',
    name: RouteEnum.APPLICANTS.INDEX,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'admin:applicants.index' */ '~/pages/admin/applicants/index.vue'
        ),
      ),
  },
  {
    path: '/applicants/:id',
    name: RouteEnum.APPLICANTS.SHOW,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'admin:applicants.show' */ '~/pages/admin/applicants/show.vue'),
      ),
  },
];
