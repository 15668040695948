import type { GetterTree, ActionTree, MutationTree } from 'vuex';
import type { RootState } from './index';

const DEFAULT_TIMEOUT = 5000;

export interface NotificationState {
  defaultTimeout: number;
  nextId: number;
  notifications: any[];
}

export const state = (): NotificationState => ({
  defaultTimeout: DEFAULT_TIMEOUT,
  nextId: 1,
  notifications: [],
});

export const getters: GetterTree<NotificationState, RootState> = {
  all(state) {
    return state.notifications;
  },
};

export const mutations: MutationTree<NotificationState> = {
  PUSH(state, notification) {
    state.notifications.push(notification);
  },

  REMOVE(state, id) {
    const notifications = [...state.notifications];
    notifications.splice(
      notifications.findIndex(notification => notification.id === id),
      1,
    );
    state.notifications = [...notifications];
  },

  INCREMENT_ID(state) {
    state.nextId += 1;
  },
};

export const actions: ActionTree<NotificationState, RootState> = {
  pushSuccess({ dispatch }, payload) {
    let title = payload;
    let message = null;
    let callback = null;

    if (typeof payload === 'object') {
      title = payload.title;
      message = payload.message ?? null;
      callback = payload.callback ?? null;
    }

    dispatch('push', {
      title,
      message,
      callback,
      type: 'success',
      icon: 'check',
    });
  },
  pushError({ dispatch }, payload) {
    let title = payload;
    let message = null;
    let callback = null;

    if (typeof payload === 'object') {
      title = payload.title;
      message = payload.message ?? null;
      callback = payload.callback ?? null;
    }

    dispatch('push', {
      title,
      message,
      callback,
      type: 'danger',
      icon: 'times',
    });
  },
  pushPending({ dispatch }, payload) {
    let title = payload;
    let message = null;
    let callback = null;

    if (typeof payload === 'object') {
      title = payload.title;
      message = payload.message ?? null;
      callback = payload.callback ?? null;
    }

    dispatch('push', {
      title,
      message,
      callback,
      type: 'info',
      icon: 'spinner',
    });
  },
  pushWarning({ dispatch }, payload) {
    let title = payload;
    let message = null;
    let callback = null;
    let timeout = DEFAULT_TIMEOUT;

    if (typeof payload === 'object') {
      title = payload.title;
      message = payload.message ?? null;
      callback = payload.callback ?? null;
      timeout = payload.timeout ?? DEFAULT_TIMEOUT;
    }

    dispatch('push', {
      title,
      message,
      callback,
      timeout,
      type: 'warning',
      icon: 'exclamation-triangle',
    });
  },
  push({ commit, state }, payload) {
    const notification = payload;
    notification.id = state.nextId;

    if (!Object.prototype.hasOwnProperty.call(notification, 'timeout')) {
      notification.timeout = state.defaultTimeout;
    }
    if (!Object.prototype.hasOwnProperty.call(notification, 'callback')) {
      notification.callback = null;
    }

    commit('PUSH', notification);
    commit('INCREMENT_ID');
  },

  remove({ commit }, id) {
    commit('REMOVE', id);
  },
};
