import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/email-templates',
    name: RouteEnum.EMAIL_TEMPLATES.INDEX,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:email-templates.index' */ '~/pages/employee/email-templates/index.vue'
        ),
      ),
  },
  {
    path: '/email-templates/create',
    name: RouteEnum.EMAIL_TEMPLATES.CREATE,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:email-templates.edit-create' */ '~/pages/employee/email-templates/edit-create.vue'
        ),
      ),
  },
  {
    path: '/email-templates/:email_template_id/edit',
    name: RouteEnum.EMAIL_TEMPLATES.EDIT,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:email-templates.edit-create' */ '~/pages/employee/email-templates/edit-create.vue'
        ),
      ),
  },
];
