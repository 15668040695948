import Tenant from '~/models/Tenant';

export default async function fetchTenant({ req, redirect, $notify, store, app }) {
  const defaultSubdomain = 'app';
  const host = req ? req.headers.host : window.location.hostname;

  const parts = host.split('.');
  const subdomain = parts[0];

  if (!subdomain) {
    $notify.error(app.i18n.t('No subdomain.'));
    redirect('/');
    return;
  }

  const tenant = await store.dispatch('tenant/fetch', subdomain);

  if (!tenant && subdomain !== defaultSubdomain) {
    $notify.error(app.i18n.t('No tenant found.'));

    const url = window.location.href;
    const defaultUrl = url.replace(subdomain, defaultSubdomain);

    redirect(defaultUrl);
    return;
  }

  const root = document.documentElement;
  root.style.setProperty('--primary-color', tenant.color);
  root.style.setProperty('--primary-color-rgb', Tenant.getRgbString(tenant.color));
}
