import { interopDefault } from '~/utils';

export default () => [
  {
    path: '*',
    component: () => interopDefault(import(/* webpackChunkName: 'error' */ '~/layouts/error.vue')),
    props: {
      error: {
        statusCode: 404,
      },
    },
  },
];
