import Model from '~/models/Model';
import User, { UserData } from '~/models/User';

export interface MeData {

  user: UserData | undefined;
}

export default class Me extends Model implements MeData {
  user: UserData | undefined;

  resource() {
    return 'auth/me';
  }

  // @ts-ignore
  relations(): Record<string, typeof Model> {
    return {
      user: User,
    };
  }
}
