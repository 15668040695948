import Model from '~/models/Model';

export interface UserData {
  id: number | undefined;
  type: string | undefined;
}

export default class User extends Model implements UserData {
  public id: number | undefined;
  public type: string | undefined;

  constructor(attributes: UserData) {
    super(attributes);

    this.id = attributes?.id;
    this.type = attributes?.type;
  }

  resource() {
    return 'v1/users';
  }
}
