import Vue from 'vue';
import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  required,
  email,
  confirmed,
  alpha_num as alphaNum,
  numeric,
  length,
  min,
  max,
  min_value as minValue,
  max_value as maxValue,
  mimes,
  regex,
  size,
  image,
  integer,
} from 'vee-validate/dist/rules';
import de from 'vee-validate/dist/locale/de.json';
import age from '~/support/validation/age';
import nullable from '~/support/validation/nullable';
import string from '~/support/validation/string';
import timeIntervalAfter from '~/support/validation/time_interval_after';
import timeIntervalBefore from '~/support/validation/time_interval_before';
import timeIntervalMax from '~/support/validation/time_interval_max';
import url from '~/support/validation/url';
import password from '~/support/validation/password';
import requiredTypeahead from '~/support/validation/required_typeahead';
import color from '~/support/validation/color';
import phonenumber from '~/support/validation/phonenumber';
import invitationLink from '~/support/validation/invitation_link';

Vue.component('validation-observer', ValidationObserver);
Vue.component('validation-provider', ValidationProvider);

localize('de', de);

extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
extend('alpha_num', alphaNum);
extend('length', length);
extend('min', min);
extend('max', max);
extend('min_value', minValue);
extend('max_value', maxValue);
extend('mimes', mimes);
extend('regex', regex);
extend('size', size);
extend('image', image);
extend('integer', integer);
extend('string', string);
extend('nullable', nullable);
extend('url', url);
extend('age', age);
extend('time_interval_max', timeIntervalMax);
extend('time_interval_before', timeIntervalBefore);
extend('time_interval_after', timeIntervalAfter);
extend('password', password);
extend('required_typeahead', requiredTypeahead);
extend('color', color);
extend('phonenumber', phonenumber);
extend('invitation_link', invitationLink);
extend('numeric', numeric);
