import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/employees',
    name: RouteEnum.EMPLOYEES.INDEX,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'tenantmember:employees.index' */ '~/pages/tenantmember/employees/index.vue'
        ),
      ),
  },
];
