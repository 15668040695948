import { Context } from '@nuxt/types';
import { getTokenQuery } from '~/utils';

export default function({ $auth, redirect, $RouteEnum, from }: Context) {
  if ($auth.loggedIn && !$auth.isVerified) {
    redirect({
      name: $RouteEnum.VERIFY_EMAIL,
      // @ts-ignore
      query: getTokenQuery(from),
    });
  }
}
