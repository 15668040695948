<template>
  <t-document standalone>
    <!-- TODO: remove -->
    <lang-switcher />
    <nuxt />
    <portal-target name="modals" multiple />
    <notifications />
  </t-document>
</template>
<script>
import Notifications from '~/layouts/partials/Notifications';
// TODO: remove
import LangSwitcher from '~/components/LangSwitcher';

export default {
  components: { Notifications, LangSwitcher },
};
</script>
