// @ts-ignore
const validate = (value) => {
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
  return passwordRegex.test(value);
};

const message =
  '{_field_} muss mindestens enthalten: 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Zahl';

export default {
  validate,
  message,
};
