import { RuleParamSchema } from 'vee-validate/dist/types/types';
import { timeParse } from '~/utils';

const validate = (value: string, { end }: Record<string, any> = {}) => {
  return !end || timeParse(value).isBefore(timeParse(end));
};

const params: RuleParamSchema[] = [
  {
    name: 'end',
    isTarget: true,
  },
];

const message = '{_field_} muss vor {end} sein.';

export default {
  validate,
  params,
  message,
};
