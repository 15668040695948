<template>
  <div class="t-datepicker">
    <date-picker v-model="computedValue" mode="date" :locale="$i18n.locale">
      <template #default="{ inputValue, inputEvents }">
        <t-input
          :value="inputValue"
          @input.native="inputEvents.input"
          @change.native="inputEvents.change"
          @keyup.native="inputEvents.keyup"
          @click.native="inputEvents.click"
          @mousemove.native="inputEvents.mousemove"
          @mouseleave.native="inputEvents.mouseleave"
          @focusin.native="inputEvents.focusin"
          @focusout.native="inputEvents.focusout"
        >
          <template v-if="$slots.left" #left>
            <slot name="left" />
          </template>
          <template v-if="$slots.right" #right>
            <slot name="right" />
          </template>
        </t-input>
      </template>
    </date-picker>
  </div>
</template>
<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Vue from 'vue';

export default Vue.extend({
  components: {
    DatePicker,
  },

  props: {
    value: {
      type: [String, Date],
      required: false,
      default: '',
    },
  },

  data() {
    return {
      newValue: this.getDateFromString(this.value),
    };
  },

  computed: {
    computedValue: {
      get() {
        return this.newValue;
      },

      set(value) {
        this.newValue = value;
        // this.$emit('input', this.getStringFromDate(value));
        this.$emit('input', value);
        this.$emit('change', value);
      },
    },
  },

  watch: {
    value(value) {
      // this.newValue = this.getDateFromString(value);
      this.newValue = value;
    },
  },

  methods: {
    // getStringFromDate(value) {
    //   if (!value) {
    //     return '';
    //   }
    //
    //   return timeFormat(this.$dayjs(value));
    // },

    getDateFromString(value) {
      if (!value) {
        return null;
      }

      if (value instanceof Date) {
        return value;
      }

      return this.$dayjs(value).toDate();
    },
  },
});
</script>
<style lang="scss">
.t-datepicker {
}
</style>
