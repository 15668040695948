import { Loader } from '@googlemaps/js-api-loader';
export default function useGoogleMaps() {
  var config = useRuntimeConfig();
  var loader = new Loader({
    apiKey: config.public.GOOGLEMAPS_API_KEY,
    version: 'weekly',
    libraries: ['places']
  });
  return {
    loader: loader
  };
}