// @ts-ignore
const validate = (value) => {
  return !!value?.id || !!value?.label;
};

const message = '{_field_} ist ein Pflichtfeld.';

export default {
  validate,
  message,
};
