<template>
  <transition-group name="fade" tag="t-notification-group">
    <t-notification
      v-for="notification in notifications"
      :key="notification.id"
      :timeout="notification.timeout"
      :auto-dismissing="notification.timeout !== 0"
      :type="notification.type"
      :icon="notification.icon"
      @closed="handle(notification)"
    >
      <strong v-if="notification.title">{{ notification.title }}<br></strong>
      <span v-if="notification.message">{{ notification.message }}</span>
    </t-notification>
  </transition-group>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      notifications: 'notification/all',
    }),
  },

  methods: {
    handle(notification) {
      if (
        Object.prototype.hasOwnProperty.call(notification, 'callback') &&
        notification.callback !== null
      ) {
        return notification.callback();
      }
      return this.$notify.remove(notification.id);
    },
  },
};
</script>
