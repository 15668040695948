<template>
  <div class="applicant-form">
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <t-validation-wrapper
          vid="date_of_birth"
          :name="$t('fields.date_of_birth')"
          :label="$t('fields.date_of_birth')"
          rules="required|age:16"
        >
          <t-datepicker v-model="form.date_of_birth">
            <t-icon slot="right" icon="baby" />
          </t-datepicker>
        </t-validation-wrapper>

        <t-validation-wrapper
          vid="graduation_type_id"
          :name="$t('fields.graduation_type')"
          rules="required|string"
        >
          <GraduationTypeSelect v-model="form.graduation_type_id" :multiple="false" />
        </t-validation-wrapper>

        <t-validation-wrapper
          vid="occupation_id"
          :name="$t('fields.occupation')"
          rules="required|string"
        >
          <OccupationSelect
            v-model="form.occupation_id"
            :multiple="false"
            :disabled="form.had_occupation"
          />
        </t-validation-wrapper>

        <slot name="footer" :invalid="invalid" :loading="loading" />
      </t-form>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue';
import FormBaseMixin from '../../FormBaseMixin';
import Applicant from '~/models/Applicant';
import TDatepicker from '~/components/TDatepicker';
import OccupationSelect from '~/components/ResourceSelect/OccupationSelect';
import GraduationTypeSelect from '~/components/ResourceSelect/GraduationTypeSelect';

export default Vue.extend({
  components: {
    TDatepicker,
    OccupationSelect,
    GraduationTypeSelect,
  },

  mixins: [FormBaseMixin],

  props: {
    applicant: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: null,
  }),

  created() {
    this.form = this.getForm(this.applicant);
  },

  methods: {
    async request(payload) {
      const model = await new Applicant({ id: this.applicant.id, ...payload }).patch();
      this.$emit('updated', model);

      return model;
    },

    getForm(model = null) {
      return {
        graduation_type_id: model?.graduation_type_id,
        occupation_id: model?.occupation_id,
        date_of_birth: model?.date_of_birth,
        had_occupation: !!model?.occupation_id,
      };
    },

    getPayload() {
      return { ...this.form };
    },
  },
});
</script>
