<template>
  <div class="t-loading" :class="[{ 't-loading--centered': centered }]">
    <component :is="image" />
  </div>
</template>

<script>
export default {
  props: {
    centered: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  computed: {
    image() {
      return () =>
        import(
          /* webpackChunkName: "loading" */
          /* webpackMode: "lazy" */
          '~/assets/components/loading.svg?inline'
        );
    },
  },
};
</script>

<style lang="scss">
.t-loading {
  display: flex;
  width: 100%;
  height: 100%;

  & [data-themeable='true'] {
    stroke: var(--primary-color);
  }

  &--centered {
    justify-content: center;
    align-items: center;
  }
}
</style>
