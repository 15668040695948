import Step from './Step';

export default class Flow {
  constructor(public steps: Step[]) {
    steps.forEach((step, index) => {
      const prev = steps[index - 1];
      const next = steps[index + 1];

      step.setPrev(prev);
      step.setNext(next);
    });

    this.steps = steps;
  }

  public find(stepId: string): Step {
    const step = this.steps.find(step => step.id === stepId);

    if (!step) {
      throw new Error('Invalid step id');
    }

    return step;
  }

  public count() {
    return this.steps.length;
  }

  public findIndex(stepId: string): number {
    const index = this.steps.findIndex(step => step.id === stepId);

    if (index === -1) {
      throw new Error('Invalid step id');
    }

    return index;
  }

  public completed(data: Record<string, any>) {
    const lastCompletedIndex = this.getLastCompletedIndex(data);

    if (lastCompletedIndex === null) {
      return false;
    }

    return lastCompletedIndex === this.steps.length - 1;
  }

  public isValid(data: Record<string, any>, step: Step) {
    // check that all previous steps are completed or optional;
    const index = this.steps.findIndex(s => s.id === step.id);
    const lastValidIndex = this.getLastValidIndex(data);

    return index <= lastValidIndex;
  }

  public getLastValid(data: Record<string, any>, allowOptional = true) {
    return this.steps[this.getLastValidIndex(data, allowOptional)];
  }

  public getLastValidIndex(data: Record<string, any>, allowOptional = true): number {
    const lastCompletedIndex = this.getLastCompletedIndex(data, allowOptional);

    if (lastCompletedIndex === null) {
      return 0;
    }

    return Math.min(lastCompletedIndex + 1, this.steps.length - 1);
  }

  private getLastCompletedIndex(data: Record<string, any>, allowOptional = true): number | null {
    let i = 0;
    let step = this.steps[i];

    const check = (step: Step): boolean => {
      if (allowOptional) {
        return step.isComplete(data) || step.isOptional;
      }

      return step.isComplete(data);
    };

    while (step && check(step)) {
      i++;
      step = this.steps[i];
    }

    // No step completed.
    if (i === 0) {
      return null;
    }

    return i - 1;
  }
}
