<script>
import Vue from 'vue';
import Step from './Build/Step';
import FlowStepFooter from './FlowStepFooter';

export default Vue.extend({
  components: {
    FlowStepFooter,
  },

  props: {
    step: {
      type: Step,
      required: true,
    },
  },

  methods: {
    moveNext() {
      this.$emit('next');
    },

    movePrev() {
      this.$emit('prev');
    },

    updateData(newData) {
      this.$emit('update-data', newData);
    },
  },
});
</script>
