import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/alerts',
    name: RouteEnum.ALERTS.INDEX,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'employee:alerts.index' */ '~/pages/employee/alerts/index.vue'),
      ),
  },
  {
    path: '/alerts/create',
    name: RouteEnum.ALERTS.CREATE,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:alerts.edit-create' */ '~/pages/employee/alerts/edit-create.vue'
        ),
      ),
  },
  {
    path: '/alerts/:alert_id/edit',
    name: RouteEnum.ALERTS.EDIT,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:alerts.edit-create' */ '~/pages/employee/alerts/edit-create.vue'
        ),
      ),
  },
];
