<template>
  <div class="applicant-form">
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <Address v-model="form.address" />

        <slot name="footer" :invalid="invalid" :loading="loading" />
      </t-form>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue';
import FormBaseMixin from '../../FormBaseMixin';
import Applicant from '~/models/Applicant';
import Address from '~/components/Fields/Address';

export default Vue.extend({
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Address,
  },

  mixins: [FormBaseMixin],

  props: {
    applicant: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: null,
  }),

  created() {
    this.form = this.getForm(this.applicant);
  },

  methods: {
    async request(payload) {
      const model = await new Applicant({ id: this.applicant.id, ...payload }).patch();
      this.$emit('updated', model);

      return model;
    },

    getForm(model = null) {
      return {
        address: {
          line_1: model?.address?.line_1,
          line_2: model?.address?.line_2,
          postcode: model?.address?.postcode,
          city: model?.address?.city,
          country: model?.address?.country,
        },
      };
    },

    getPayload() {
      return { ...this.form };
    },
  },
});
</script>
