import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/organization',
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:organization' */ '~/pages/employee/organization/show.vue'
        ),
      ),
    children: [
      {
        path: '',
        name: RouteEnum.ORGANIZATION.DETAILS,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'employee:organization.details' */ '~/pages/employee/organization/details.vue'
            ),
          ),
      },
      {
        path: 'employees',
        name: RouteEnum.ORGANIZATION.EMPLOYEES,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'employee:organization.employees' */ '~/pages/employee/organization/employees.vue'
            ),
          ),
      },
      {
        path: 'invitations',
        name: RouteEnum.ORGANIZATION.INVITATIONS,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'employee:organization.invitations' */ '~/pages/employee/organization/invitations.vue'
            ),
          ),
      },
    ],
  },
];
