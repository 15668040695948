import { Context } from '@nuxt/types';
import { getTokenQuery } from '~/utils';

export default function({ $auth, redirect, $RouteEnum, from }: Context) {
  if ($auth.loggedIn && $auth.isApplicant && !$auth.me.tos_accepted) {
    redirect({
      name: $RouteEnum.TOS.ACCEPT,
      // @ts-ignore
      query: getTokenQuery(from),
    });
  }
}
