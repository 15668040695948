import { ActionTree, GetterTree, MutationTree } from 'vuex';
import UserTypeEnum from '~/enums/UserTypeEnum';
import Me from '~/models/Me';
import { RootState } from '~/store/index';

export interface AuthState {
  loggedIn: boolean;
  me: any;
}

export const state = (): AuthState => ({
  loggedIn: false,
  me: null,
});

export const getters: GetterTree<AuthState, RootState> = {
  isLoggedIn(state) {
    return state.loggedIn;
  },
  isGuest(_state, getters) {
    return !getters.isLoggedIn;
  },
  user(state) {
    return state.me?.user;
  },
  userType(state) {
    return state.me?.user?.type;
  },
  isVerified(state) {
    return state.me?.user?.verified;
  },
  isApplicant(_state, getters) {
    return getters.userType === UserTypeEnum.APPLICANT;
  },
  isEmployee(_state, getters) {
    return getters.userType === UserTypeEnum.EMPLOYEE;
  },
  isTenantMember(_state, getters) {
    return getters.userType === UserTypeEnum.TENANT_MEMBER;
  },
  isAdmin(_state, getters) {
    return getters.userType === UserTypeEnum.ADMIN;
  },
};

export const mutations: MutationTree<AuthState> = {
  SET_LOGGED_IN(state, value) {
    state.loggedIn = value;
  },
  SET_ME(state, value): void {
    state.me = value;
    if (state.me?.user?.type) {
      // @ts-ignore
      this.$router.setUserType(state.me.user.type);
    }
  },
  PATCH_ME(state, value) {
    state.me = {
      ...state.me,
      ...value,
    };
    if (state.me?.user?.type) {
      // @ts-ignore
      this.$router.setUserType(state.me.user.type);
    }
  },
  SET_USER(state, value) {
    state.me.user = value;
  },
  PATCH_USER(state, value) {
    state.me.user = {
      ...state.me.user,
      ...value,
    };
  },
  SET_VERIFIED(state) {
    state.me.user.verified = true;
  },
  SET_ORGANIZATION(state, value) {
    state.me.organization = value;
  },
};

export const actions: ActionTree<AuthState, RootState> = {
  fetchMe(_, disableGlobalErrorHandler = false) {
    // @ts-ignore
    return Me.config({
      disableGlobalErrorHandler,
    }).$first();
  },
  async login({ commit, dispatch }, credentials) {
    await this.$axios.$get('/auth/csrf-cookie');
    await this.$axios.$post('/auth/login', credentials);

    const me = await dispatch('fetchMe');
    commit('SET_LOGGED_IN', true);
    commit('SET_ME', me);
  },

  async register({ commit, dispatch }, { userType, payload }) {
    await this.$axios.$get('/auth/csrf-cookie');
    await this.$axios.$post(`/auth/register/${userType}`, payload);

    const me = await dispatch('fetchMe');
    commit('SET_LOGGED_IN', true);
    commit('SET_ME', me);
  },

  async logout({ commit }) {
    try {
      await this.$axios.$post('/auth/logout', null, {
        // @ts-ignore
        isLogout: true,
      });

      commit('SET_LOGGED_IN', false);
      commit('SET_ME', null);
    } catch (e) {
      // If we get a 401, the user is already logged out
      // so we just sync frontend.
      // @ts-ignore
      const statusCode = e?.response?.status;
      if (statusCode === 401) {
        commit('SET_LOGGED_IN', false);
        commit('SET_ME', null);
        return;
      }

      throw e;
    }
  },

  async forgotPassword(_, email) {
    await this.$axios.$get('/auth/csrf-cookie');
    await this.$axios.$post('/auth/password/forgot', email);
  },

  async resetPassword(_, payload) {
    await this.$axios.$get('/auth/csrf-cookie');
    await this.$axios.$post('/auth/password/reset', payload);
  },

  async changePassword(_, payload) {
    await this.$axios.$post('/auth/password/change', payload);
  },

  async handleExpiredLocalStorage({ state, commit, dispatch }) {
    // Nothing to do.
    if (state.loggedIn) {
      return;
    }

    // In some extreme cases, the browser might flush local storage.
    // In that case, we might end up with a user still being logged in
    // in the API but marked as not logged in the SPA.
    // The above will cause issues, as login endpoints will throw a 403.
    try {
      // If we can get the me, it means that we are still logged in
      // in the API. As such, we sync the state in the SPA to match.
      const me = await dispatch('fetchMe', true);
      commit('SET_LOGGED_IN', true);
      commit('SET_ME', me);
    } catch (e) {
      // Nothing to do, we expect a 401.
    }
  },
};
