import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/search',
    name: RouteEnum.SEARCH,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'employee:search' */ '~/pages/employee/search.vue'),
      ),
  },
];
