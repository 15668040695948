import { Context } from '@nuxt/types';
import { NuxtAxiosInstance } from '@nuxtjs/axios';
import { captureException } from '@sentry/vue';
import { AxiosError } from 'axios';
import createCsrfTokenRefreshInterceptor from 'axios-auth-refresh';
import { ValidationObserver } from 'vee-validate';
import { Notify } from './notify';
declare module 'axios' {
  interface AxiosRequestConfig {
    disableGlobalErrorHandler?: boolean;
    isLogout?: boolean;
  }
}

declare module '@nuxtjs/axios' {
  interface NuxtAxiosInstance {
    handleError(e: AxiosError, validationObserver?: InstanceType<typeof ValidationObserver>): void;
  }
}

const handleUnauthorizedError = async(_e: AxiosError, { $auth, redirect }: Context) => {
  // TODO: maybe show a notice.
  await $auth.logout();
  await redirect({ name: 'login' });
};

const handleTooManyRequest = async(_e: AxiosError, { $notify, app }: Context) => {
  await $notify.error(app.i18n.t('Too many requests. Please wait a minute and try again.'));
};

const handleBadRequestError = async(_e: AxiosError, $notify: Notify) => {
  const message = _e.response?.data?.message;
  if (!message) {
    await $notify.unknownError();
    return;
  }

  await $notify.error(message);
};

// eslint-disable-next-line
const handleUnknownError = async (res: any, $notify: Notify) => {
  await $notify.unknownError();
};

// TODO: we need to check if this will cause a loop.
// we might need a different instance of axios.
const getRefreshCsrfToken = ($axios: NuxtAxiosInstance) => (): Promise<any> =>
  new Promise((resolve, reject) => {
    $axios.get('/auth/csrf-cookie').then(resolve).catch(reject);
  });

const getValidationErrors = (error: AxiosError) => {
  const data = error?.response?.data;
  return data.meta.errors;
};

const getGlobalHandleResponseError = (context: Context) => async(e: AxiosError) => {
  const { response, config } = e;

  if (config && config.disableGlobalErrorHandler) {
    return Promise.reject(e);
  }

  const statusCode = response?.status;

  // Check for isLogout to avoid infinite loop
  // in case logout endpoint throws a 401.
  if (statusCode && statusCode === 401 && !config.isLogout) {
    await handleUnauthorizedError(e, context);
  }

  if (statusCode && statusCode === 429) {
    await handleTooManyRequest(e, context);
  }

  captureException(e);
  return Promise.reject(e);
};

const getLocalHandleResponseError =
  ($notify: Notify) =>
    async(e: AxiosError, validationObserver: InstanceType<typeof ValidationObserver>) => {
      const statusCode = e?.response?.status;

      if (!statusCode) {
        await handleUnknownError(e, $notify);
        return;
      }

      // Handled as a global interceptors.
      if (statusCode === 401 || statusCode === 419) {
        return;
      }

      if (statusCode === 422 && validationObserver) {
        validationObserver.setErrors(getValidationErrors(e));
        return;
      }

      if (statusCode === 400 || statusCode === 409) {
        await handleBadRequestError(e, $notify);
        return;
      }

      // TODO: handle api custom codes.
      await handleUnknownError(e, $notify);
    };

export default function axios(context: Context) {
  const refreshCsrfToken = getRefreshCsrfToken(context.$axios);

  // Handle 419 status.
  // @ts-ignore
  createCsrfTokenRefreshInterceptor(context.$axios, refreshCsrfToken, {
    statusCodes: [419],
  });

  context.$axios.setBaseURL(context.$config.public.API_URL);

  // @ts-ignore
  context.$axios.onResponseError(getGlobalHandleResponseError(context));
  context.$axios.handleError = getLocalHandleResponseError(context.$notify);
}
