import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/login',
    name: RouteEnum.LOGIN,
    component: () =>
      interopDefault(import(/* webpackChunkName: 'login' */ '~/pages/auth/login.vue')),
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: '/password/forgot',
    name: RouteEnum.FORGOT_PASSWORD,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'forgot-password' */ '~/pages/auth/forgot-password.vue'),
      ),
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: '/password/reset',
    name: RouteEnum.RESET_PASSWORD,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'reset-password' */ '~/pages/auth/reset-password.vue'),
      ),
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: '/register/applicant',
    name: RouteEnum.REGISTER_APPLICANT,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'register-applicant' */ '~/pages/auth/register-applicant.vue'),
      ),
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: '/register/employee',
    name: RouteEnum.REGISTER_EMPLOYEE,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'register-employee' */ '~/pages/auth/register-employee.vue'),
      ),
    meta: {
      requiresNoAuth: true,
    },
  },
  {
    path: '/logout',
    name: RouteEnum.LOGOUT,
    component: () =>
      interopDefault(import(/* webpackChunkName: 'logout' */ '~/pages/auth/logout.vue')),
  },
  {
    path: '/auth/verify-email/',
    name: RouteEnum.VERIFY_EMAIL,
    component: () =>
      interopDefault(import(/* webpackChunkName: 'verify' */ '~/pages/auth/verify-email.vue')),
    meta: {
      requiresNoAuth: true,
    },
  },
];
