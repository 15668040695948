import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/employees',
    name: RouteEnum.EMPLOYEES.INDEX,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'admin:employees.index' */ '~/pages/admin/employees/index.vue'),
      ),
  },
  {
    path: '/employees/:id',
    name: RouteEnum.EMPLOYEES.SHOW,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'admin:employees.show' */ '~/pages/admin/employees/show.vue'),
      ),
  },
];
