import Vue from 'vue';
import Router, { RawLocation, Route } from '@stafftastic/vue-router';
// @ts-ignore
import * as qs from 'qs';
import scrollBehavior from '~/router.scrollBehavior';
import routes from '~/routes';
import getRoutesForUserType from '~/routes/getRoutesForUserType';

Vue.use(Router);

declare module '@stafftastic/vue-router' {
  interface VueRouter {
    setUserType(userType: string): Function;
    pushExpectRedirect(
      location: RawLocation,
      onResolve?: Function,
      onReject?: (e: Error) => any
    ): void | Promise<Route>;
  }
}

export function createRouter() {
  const router = new Router({
    mode: 'history',
    routes: routes(),
    scrollBehavior,
    parseQuery(query) {
      return qs.parse(query);
    },
    stringifyQuery(query) {
      const string = qs.stringify(query, { encode: false });
      return string ? `?${string}` : '';
    },
  });

  // @ts-ignore
  router.pushExpectRedirect = async function(
    location: RawLocation,
    onResolve?: Function,
    onReject?: (e: Error) => any,
    // @ts-ignore
  ): void | Promise<Route> {
    try {
      return await router.push(location, onResolve, onReject);
    } catch (e) {
      // https://stackoverflow.com/a/65326844/6543983
      const { isNavigationFailure, NavigationFailureType } = Router;
      if (isNavigationFailure(e, NavigationFailureType.redirected)) {
        // The user might be redirected to onboarding, so we ignore.
        return;
      }

      throw e;
    }
  };

  let prevUserType: string | null = null;
  // @ts-ignore
  router.setUserType = function(userType: string): void {
    if (prevUserType && prevUserType === userType) {
      return;
    }

    const routes = getRoutesForUserType(userType);

    routes.forEach((route) => {
      // @ts-ignore
      this.addRoute(route);
    });

    prevUserType = userType;
  };

  return router;
}
