import { Context } from '@nuxt/types';

// @ts-ignore

var getNotify = function getNotify(store, i18n) {
  return {
    push: function push(payload) {
      return store.dispatch('notification/push', payload);
    },
    success: function success(payload) {
      return store.dispatch('notification/pushSuccess', payload);
    },
    error: function error(payload) {
      return store.dispatch('notification/pushError', payload);
    },
    pending: function pending(payload) {
      return store.dispatch('notification/pushPending', payload);
    },
    warning: function warning(payload) {
      return store.dispatch('notification/pushWarning', payload);
    },
    remove: function remove(payload) {
      return store.dispatch('notification/remove', payload);
    },
    unknownError: function unknownError() {
      return store.dispatch('notification/pushError', i18n.t('An unknown error occurred. Please try again later. If the issue persists, please contact us.'));
    },
    all: store.getters['notification/all']
  };
};
var notifyPlugin = function notifyPlugin(_ref, inject) {
  var store = _ref.store,
    app = _ref.app;
  inject('notify', getNotify(store, app.i18n));
};
export default notifyPlugin;