import { Ability } from '@casl/ability';
import applicantAbilities from '~/abilities/applicant';
import employeeAbilities from '~/abilities/employee';
import tenantMemberAbilities from '~/abilities/tenantmember';
import adminAbilities from '~/abilities/admin';
import UserTypeEnum from '~/enums/UserTypeEnum';

export default (userType: string): Ability => {
  if (userType === UserTypeEnum.APPLICANT) {
    return applicantAbilities;
  }

  if (userType === UserTypeEnum.EMPLOYEE) {
    return employeeAbilities;
  }

  if (userType === UserTypeEnum.TENANT_MEMBER) {
    return tenantMemberAbilities;
  }

  if (userType === UserTypeEnum.ADMIN) {
    return adminAbilities;
  }

  return new Ability([]);
};
