import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/registration-codes',
    name: RouteEnum.REGISTRATION_CODES.INDEX,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:registration-codes.index' */ '~/pages/employee/registration-codes/index.vue'
        ),
      ),
  },
  {
    path: '/registration-codes/create',
    name: RouteEnum.REGISTRATION_CODES.CREATE,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:registration-codes.edit-create' */ '~/pages/employee/registration-codes/edit-create.vue'
        ),
      ),
  },
  {
    path: '/registration-codes/:registration_code_id/edit',
    name: RouteEnum.REGISTRATION_CODES.EDIT,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:registration-codes.edit-create' */ '~/pages/employee/registration-codes/edit-create.vue'
        ),
      ),
  },
];
