const validate = (value: any) => {
  let url;
  try {
    url = new URL(value);
  } catch (exception) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

const message = '{_field_} muss eine gültige URL sein.';

export default {
  validate,
  message,
};
