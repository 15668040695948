import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/onboarding',
    name: RouteEnum.APPLICANT_ONBOARDING,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'applicant:onboarding' */ '~/pages/applicant/flows/onboarding/onboarding.vue'
        ),
      ),
  },
];
