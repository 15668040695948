<template>
  <div class="applicant-form">
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <t-validation-wrapper
          vid="meta.phonenumber"
          :name="$t('fields.phonenumber')"
          rules="required"
        >
          <t-phonenumber v-model="form.meta.phonenumber" />
        </t-validation-wrapper>

        <slot name="footer" :invalid="invalid" :loading="loading" />
      </t-form>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue';
import FormBaseMixin from '../../FormBaseMixin';
import Applicant from '~/models/Applicant';

export default Vue.extend({
  mixins: [FormBaseMixin],

  props: {
    applicant: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: null,
  }),

  created() {
    this.form = this.getForm(this.applicant);
  },

  methods: {
    async request(payload) {
      const model = await new Applicant({ id: this.applicant.id, ...payload }).patch();
      this.$emit('updated', model);

      return model;
    },

    getForm(model = null) {
      return {
        meta: {
          phonenumber: model?.meta?.phonenumber ?? '',
        },
      };
    },

    getPayload() {
      return { ...this.form };
    },
  },
});
</script>
