import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/account',
    component: () =>
      interopDefault(import(/* webpackChunkName: 'account' */ '~/pages/account/show.vue')),
    children: [
      {
        path: '',
        name: RouteEnum.ACCOUNT.GENERAL_INFORMATION,
        component: () =>
          interopDefault(
            import(
              /* webpackChunkName: 'account.general-information' */ '~/pages/account/general-information.vue'
            ),
          ),
      },
      {
        path: 'security',
        name: RouteEnum.ACCOUNT.SECURITY,
        component: () =>
          interopDefault(
            import(/* webpackChunkName: 'account.security' */ '~/pages/account/security.vue'),
          ),
      },
      {
        path: 'danger-zone',
        name: RouteEnum.ACCOUNT.DANGER_ZONE,
        component: () =>
          interopDefault(
            import(/* webpackChunkName: 'account.danger-zone' */ '~/pages/account/danger-zone.vue'),
          ),
      },
    ],
  },
];
