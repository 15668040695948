<script>
import Vue from 'vue';
import * as iso3166 from 'i18n-iso-countries';
import EnumSelect from '~/components/EnumSelect/EnumSelect';

export default Vue.extend({
  extends: EnumSelect,

  props: {
    getOptionLabel: {
      type: Function,
      required: false,
      default: resource => resource.label,
    },

    getOptionKey: {
      type: Function,
      required: false,
      default: resource => resource.key,
    },

    reduce: {
      type: Function,
      required: false,
      default: resource => resource.key,
    },
  },

  data() {
    return {
      resources: Object.keys(iso3166.getAlpha2Codes()).map(alpha2 => ({
        key: alpha2,
        label: iso3166.getName(alpha2, this.$i18n.locale, { select: 'official' }),
      })),
    };
  },
});
</script>
