<template>
  <div>
    <h1 class="mb-2">
      {{ $t('pages.onboarding.applicant.address.heading') }}
    </h1>
    <p class="text--grey mb-5">
      {{ $t('pages.onboarding.applicant.address.lead') }}
    </p>
    <step-form :applicant="applicant" @saved="onSaved">
      <template #footer="{ invalid, loading }">
        <flow-step-footer :step="step" @prev="movePrev" @skip="moveNext">
          <t-button-loading wide native-type="submit" :loading="loading" :disabled="invalid">
            {{ $t('global.actions.continue') }}
          </t-button-loading>
        </flow-step-footer>
      </template>
    </step-form>
  </div>
</template>
<script>
import StepForm from './StepForm.vue';
import FlowStep from '~/components/Flows/FlowStep.vue';

export default FlowStep.extend({
  components: {
    StepForm,
  },

  props: {
    applicant: {
      type: Object,
      required: false,
      default: null,
    },
  },

  methods: {
    onSaved(applicant) {
      this.updateData({
        applicant,
      });
      this.moveNext();
    },
  },
});
</script>
