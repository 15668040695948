import { Dayjs } from 'dayjs';
import dayjs from '~/libraries/dayjs';

const format = (value: Dayjs, format = 'HH:mm') => {
  return value.format(format);
};

const parse = (value: string, format = 'HH:mm') => {
  return dayjs(value, format);
};

export { format, parse };
