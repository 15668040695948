import { Context } from '@nuxt/types';
import { getTokenQuery } from '~/utils';

export default function({ $auth, redirect, from }: Context) {
  if ($auth.loggedIn && !$auth.hasOnboarded()) {
    redirect({
      name: $auth.getOnboardingRoute(),
      // @ts-ignore
      query: getTokenQuery(from),
    });
  }
}
