<template>
  <div
    v-if="isDev"
    :style="{ position: 'fixed', width: '100%', zIndex: 100, padding: '10px', textAlign: 'center', pointerEvents: 'none' }"
  >
    <t-button
      v-for="locale in $i18n.locales"
      :key="locale.code"
      :style="{pointerEvents: 'auto'}"
      :type="locale.code === $i18n.locale ? 'danger' : 'simple'"
      @click="$i18n.setLocale(locale.code)"
    >
      {{ locale.code }}
    </t-button>
  </div>
</template>

<script>
export default {
  computed: {
    isDev() {
      return process.env.NODE_ENV === 'development';
    },
  },
};
</script>
