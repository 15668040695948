// @ts-ignore
import ProfileStep from './Steps/Profile/ProfileStep';
// @ts-ignore
import PhonenumberStep from './Steps/Phonenumber/PhonenumberStep';
// @ts-ignore
import AddressStep from './Steps/Address/AddressStep';
import Step from '~/components/Flows/Build/Step';
import Flow from '~/components/Flows/Build/Flow';

export default (): Flow => {
  const profileStep = new Step({
    id: 'profile',
    slug: 'profile',
    component: ProfileStep,
    props: ['applicant'],
    image: 'login',

    isComplete: ({ applicant }: { applicant: any }): boolean => {
      return !!(
        applicant?.occupation_id &&
        applicant?.graduation_type_id &&
        applicant?.date_of_birth
      );
    },
  });

  const phonenumberStep = new Step({
    id: 'phonenumber',
    slug: 'phonenumber',
    component: PhonenumberStep,
    props: ['applicant'],
    image: 'login',

    isComplete: ({ applicant }: { applicant: any }): boolean => {
      return !!applicant?.meta?.phonenumber;
    },
  });

  const addressStep = new Step({
    id: 'address',
    slug: 'address',
    component: AddressStep,
    props: ['applicant'],
    image: 'login',

    isComplete: ({ applicant }: { applicant: any }): boolean => {
      return !!applicant?.address;
    },
  });

  return new Flow([profileStep, phonenumberStep, addressStep]);
};
