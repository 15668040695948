import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/profiles',
    name: RouteEnum.PROFILES.INDEX,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:profiles.index' */ '~/pages/employee/profiles/index.vue'
        ),
      ),
  },
  {
    path: '/profiles/create',
    name: RouteEnum.PROFILES.CREATE,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:profiles.edit-create' */ '~/pages/employee/profiles/edit-create.vue'
        ),
      ),
  },
  {
    path: '/profiles/:profile_id/edit',
    name: RouteEnum.PROFILES.EDIT,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'employee:profiles.edit-create' */ '~/pages/employee/profiles/edit-create.vue'
        ),
      ),
  },
];
