import Model from '~/models/Model';

export default class Organization extends Model {
  resource() {
    return 'v1/organizations';
  }

  async $nearBy() {
    // @ts-ignore
    const endpoint = this.endpoint();

    const response = await this.request({
      method: 'GET',
      url: `${endpoint}/near-by${this._builder.query()}`,
    });

    return response?.data?.data;
  }

  static $nearBy() {
    const self = new this();

    return self.$nearBy();
  }

  async statistics() {
    // @ts-ignore
    const endpoint = this.endpoint();

    const response = await this.request({
      method: 'GET',
      url: `${endpoint}/_statistics`,
    });

    return response?.data;
  }

  static statistics() {
    const self = new this();

    return self.statistics();
  }
}
