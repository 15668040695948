<template>
  <span class="dashboard-image">
    <component :is="image" />
  </span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true,
    },
  },

  computed: {
    image() {
      return () =>
        import(
          /* webpackChunkName: "images" */
          /* webpackMode: "lazy-once" */
          `~/assets/components/${this.name}.svg?inline`
        );
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard-image {
  display: block;
  width: 400px;
  height: auto;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  & [data-themeable='true'] {
    fill: var(--primary-color);
  }
}
</style>
