<template>
  <div class="applicant-form">
    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <t-validation-wrapper vid="usage_type" :name="$t('fields.usage_type')" rules="required">
          <UsageTypeSelect v-model="form.usage_type" />
        </t-validation-wrapper>

        <slot name="footer" :invalid="invalid" :loading="loading" />
      </t-form>
    </validation-observer>
  </div>
</template>
<script>
import Vue from 'vue';
import FormBaseMixin from '../../FormBaseMixin';
import Organization from '~/models/Organization';
import UsageTypeSelect from '~/components/EnumSelect/UsageTypeSelect';

export default Vue.extend({
  components: {
    UsageTypeSelect,
  },

  mixins: [FormBaseMixin],

  props: {
    organization: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    form: null,
  }),

  created() {
    this.form = this.getForm(this.organization);
  },

  methods: {
    async request(payload) {
      const model = await new Organization({ id: this.organization.id, ...payload }).patch();
      this.$emit('updated', model);

      return model;
    },

    getForm(model = null) {
      return {
        usage_type: model?.usage_type,
      };
    },

    getPayload() {
      return { ...this.form };
    },
  },
});
</script>
