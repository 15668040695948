<script>
import Vue from 'vue';
import UsageTypeEnum from '~/enums/UsageTypeEnum';
import EnumSelect from '~/components/EnumSelect/EnumSelect';

export default Vue.extend({
  extends: EnumSelect,

  data: () => ({
    resources: UsageTypeEnum.all(),
  }),
});
</script>
