class UsageTypeEnum {
  static REFERRING = 'referring';
  static SEARCHING = 'searching';
  static SEARCHING_AND_REFERRING = 'searching_and_referring';

  static labelKey(item: string) {
    return UsageTypeEnum.meta(item)?.labelKey;
  }

  static meta(item: string) {
    switch (item) {
      case UsageTypeEnum.REFERRING:
        return {
          labelKey: 'global.usage_type_labels.referring',
        };
      case UsageTypeEnum.SEARCHING:
        return {
          labelKey: 'global.usage_type_labels.searching',
        };
      case UsageTypeEnum.SEARCHING_AND_REFERRING:
        return {
          labelKey: 'global.usage_type_labels.searching_and_referring',
        };
    }
  }

  static all() {
    return [
      {
        key: UsageTypeEnum.REFERRING,
        labelKey: UsageTypeEnum.labelKey(UsageTypeEnum.REFERRING),
      },
      {
        key: UsageTypeEnum.SEARCHING,
        labelKey: UsageTypeEnum.labelKey(UsageTypeEnum.SEARCHING),
      },
      {
        key: UsageTypeEnum.SEARCHING_AND_REFERRING,
        labelKey: UsageTypeEnum.labelKey(UsageTypeEnum.SEARCHING_AND_REFERRING),
      },
    ];
  }
}

export default UsageTypeEnum;
