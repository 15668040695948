const middleware = {}

middleware['admin'] = require('../middleware/admin.js')
middleware['admin'] = middleware['admin'].default || middleware['admin']

middleware['applicant'] = require('../middleware/applicant.js')
middleware['applicant'] = middleware['applicant'].default || middleware['applicant']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['employee'] = require('../middleware/employee.js')
middleware['employee'] = middleware['employee'].default || middleware['employee']

middleware['fetch-me'] = require('../middleware/fetch-me.js')
middleware['fetch-me'] = middleware['fetch-me'].default || middleware['fetch-me']

middleware['fetch-tenant'] = require('../middleware/fetch-tenant.js')
middleware['fetch-tenant'] = middleware['fetch-tenant'].default || middleware['fetch-tenant']

middleware['guest'] = require('../middleware/guest.js')
middleware['guest'] = middleware['guest'].default || middleware['guest']

middleware['onboarded'] = require('../middleware/onboarded.ts')
middleware['onboarded'] = middleware['onboarded'].default || middleware['onboarded']

middleware['tenantmember'] = require('../middleware/tenantmember.js')
middleware['tenantmember'] = middleware['tenantmember'].default || middleware['tenantmember']

middleware['tos-accepted'] = require('../middleware/tos-accepted.ts')
middleware['tos-accepted'] = middleware['tos-accepted'].default || middleware['tos-accepted']

middleware['verified'] = require('../middleware/verified.ts')
middleware['verified'] = middleware['verified'].default || middleware['verified']

export default middleware
