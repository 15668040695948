// @ts-ignore
const validate = (value) => {
  const colorRegex = /#[A-Fa-f0-9]{6}/;
  return colorRegex.test(value);
};

const message = '{_field_} ist kein gültiger Farbcode (Bsp.: #ff2d21)';

export default {
  validate,
  message,
};
