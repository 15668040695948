<script>
import Vue from 'vue';
import ResourceSelect from '~/components/ResourceSelect/ResourceSelect.vue';
import GraduationType from '~/models/GraduationType';

export default Vue.extend({
  extends: ResourceSelect,

  props: {
    query: {
      type: Object,
      required: false,
      default: () => new GraduationType(),
    },
  },

  methods: {
    fetch() {
      return this.query.$all();
    },
  },
});
</script>
