import Model from '~/models/Model';
import { hexToRgb } from '~/utils';

export default class Tenant extends Model {
  resource() {
    return 'v1/tenants';
  }

  async $bySlug(slug: string) {
    // @ts-ignore
    const endpoint = this.endpoint();

    const response = await this.request({
      method: 'GET',
      url: `${endpoint}?filter[slug]=${slug}&append=boundary`,
    });

    return response?.data?.data[0];
  }

  static $bySlug(slug: string) {
    const self = new this();

    return self.$bySlug(slug);
  }

  static getRgbString(color: string): string | null {
    const colorRgb = hexToRgb(color);
    if (!colorRgb) {
      return '';
    }
    return `${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}`;
  }
}
