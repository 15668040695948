import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '~/store/index';
import Tenant from '~/models/Tenant';

export interface AuthState {
  tenant: any;
}

export const state = (): AuthState => ({
  tenant: null,
});

export const getters: GetterTree<AuthState, RootState> = {
  tenant(state) {
    return state.tenant;
  },
};

export const mutations: MutationTree<AuthState> = {
  SET_TENANT(state, value): void {
    state.tenant = value;
  },
};

export const actions: ActionTree<AuthState, RootState> = {
  async fetch({ state, commit }, subdomain) {
    if (!state.tenant) {
      const tenant = await Tenant.$bySlug(subdomain);
      commit('SET_TENANT', tenant);
    }

    return state.tenant;
  },
};
