<template>
  <logo id="navigation-logo" />
</template>

<script>
import Logo from '~/assets/components/logo.svg?inline';

export default {
  components: {
    Logo,
  },
};
</script>

<style scoped lang="scss">
#navigation-logo {
  width: 100%;
  max-width: 36px;

  & [data-themeable='true'] {
    fill: var(--primary-color);
  }
}
</style>
