import { AxiosStatic, AxiosPromise, AxiosRequestConfig, CancelToken } from 'axios';
import { Model as BaseModel } from 'vue-api-query';

// declare module 'vue-api-query' {
//   class Model {
//      _builder: any;
//      protected _update(): Promise<this>
//   }
// }

export default class Model extends BaseModel {
  _builder: any;

  baseURL() {
    return `${this.$http.defaults.baseURL}`;
  }

  request(config: AxiosRequestConfig): AxiosPromise {
    return (this.$http as AxiosStatic).request(config);
  }

  static query() {
    // @ts-ignore
    return this.instance();
  }

  getWithCancelToken(cancelToken: CancelToken) {
    const currentRequestFunc = this.request;

    this.request = config =>
      this.$http.request({
        ...config,
        cancelToken,
      });

    const promise = this.get();

    this.request = currentRequestFunc;

    return promise;
  }

  applyFilters(filters: any) {
    Object.keys(filters).forEach((key) => {
      if (Array.isArray(filters[key])) {
        this.whereIn(key, filters[key]);
      } else {
        this.where(key, filters[key]);
      }
    });

    return this;
  }

  // @ts-ignore
  page(page: { size: number; number: number }) {
    // @ts-ignore
    this.params({
      // @ts-ignore
      page,
    });

    return this;
  }

  /**
   * Overwrite the package functionality as we needed maintain
   * the previous params to implement the custom page feature.
   *
   * @param payload
   * @returns {Model}
   */
  params(payload: Record<string, string | number | boolean>) {
    if (payload === undefined || typeof payload !== 'object') {
      throw new Error('You must pass a payload/object as param.');
    }

    this._builder.payload = {
      ...this._builder.payload,
      ...payload,
    };

    return this;
  }
}
