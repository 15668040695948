import { RuleParamSchema } from 'vee-validate/dist/types/types';
import { timeParse } from '~/utils';

const validate = (value: string, { other, maxHours }: Record<string, any> = {}) => {
  const checkMinutes = true;
  return !other || timeParse(value).diff(timeParse(other), 'hour', checkMinutes) <= maxHours;
};

const params: RuleParamSchema[] = [
  {
    name: 'other',
    isTarget: true,
  },
  {
    name: 'maxHours',
  },
];

const message = 'Der Zeitraum muss weniger als {maxHours} Stunden sein.';

export default {
  validate,
  params,
  message,
};
