<template>
  <t-grid compact>
    <t-grid-row>
      <t-grid-cell>
        <t-validation-wrapper
          vid="address.line_1"
          :name="$t('fields.address.line_1')"
          rules="required"
        >
          <div class="t-input">
            <div class="t-input__wrapper">
              <input
                ref="input"
                v-model="address.line_1"
                class="t-input__element"
                :placeholder="$t('fields.address.sample_street')"
              >
              <span class="t-input__seperator" />
            </div>
          </div>
          <span
            v-if="hasStreetNumber === false"
            class="text--danger"
          >{{ $t('global.errors.empty_house_number') }}</span>
        </t-validation-wrapper>
      </t-grid-cell>
    </t-grid-row>

    <t-grid-row>
      <t-grid-cell>
        <t-validation-wrapper vid="address.line_2" :name="$t('fields.address.line_2')" rules="">
          <t-input v-model="address.line_2" />
        </t-validation-wrapper>
      </t-grid-cell>
    </t-grid-row>

    <t-grid-row>
      <t-grid-cell>
        <t-validation-wrapper
          vid="address.postcode"
          :name="$t('fields.address.postcode')"
          rules="required"
        >
          <t-input v-model="address.postcode" />
        </t-validation-wrapper>
      </t-grid-cell>

      <t-grid-cell>
        <t-validation-wrapper vid="address.city" :name="$t('fields.address.city')" rules="required">
          <t-input v-model="address.city" />
        </t-validation-wrapper>
      </t-grid-cell>
    </t-grid-row>

    <t-grid-row>
      <t-grid-cell>
        <t-validation-wrapper
          vid="address.country"
          :name="$t('fields.address.country')"
          rules="required"
        >
          <CountrySelect v-model="address.country" />
        </t-validation-wrapper>
      </t-grid-cell>
    </t-grid-row>
  </t-grid>
</template>

<script>
import usePlacesAutocomplete from '~/composables/usePlacesAutocomplete';
import CountrySelect from '~/components/EnumSelect/CountrySelect';
import TValidationWrapper from '~/components/TValidationWrapper';

export default {
  components: { CountrySelect, TValidationWrapper },

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  setup() {
    const { buildAutocomplete, autocomplete } = usePlacesAutocomplete();

    return {
      autocomplete,
      buildAutocomplete,
    };
  },

  data() {
    return {
      hasStreetNumber: undefined,

      address: {
        line_1: this.value?.line_1,
        line_2: this.value?.line_2,
        postcode: this.value?.postcode,
        city: this.value?.city,
        country: this.value?.country,
      },
    };
  },

  watch: {
    address: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
        this.$emit('change', value);
      },
    },
  },

  mounted() {
    this.buildAutocomplete(this.$refs.input, this.placeChanged);
  },

  methods: {
    placeChanged() {
      if (this.getComponent('street_number')) {
        this.address.line_1 = `${this.getComponent('route')?.long_name} ${
          this.getComponent('street_number')?.long_name
        }`;
        this.hasStreetNumber = true;
      } else {
        this.address.line_1 = `${this.getComponent('route')?.long_name}`;
        this.hasStreetNumber = false;
      }

      this.address.line_2 = this.getComponent('subpremise')?.long_name;
      this.address.postcode = this.getComponent('postal_code')?.long_name;
      this.address.city = this.getComponent('locality')?.long_name;
      this.address.country = this.getComponent('country')?.short_name;
    },

    getComponent(type) {
      const components = this.autocomplete.value.getPlace().address_components;

      return components.find(component => component.types.includes(type));
    },
  },
};
</script>
