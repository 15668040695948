import RouteEnum from '~/enums/RouteEnum';
import { interopDefault } from '~/utils';

export default [
  {
    path: '/tos/accept',
    name: RouteEnum.TOS.ACCEPT,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'applicant:tos.accept' */ '~/pages/applicant/tos/accept.vue'),
      ),
  },
  {
    path: '/legal-contacts/:legalContactId/verify',
    name: RouteEnum.TOS.VERIFY,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'applicant:tos.verify' */ '~/pages/applicant/tos/verify.vue'),
      ),
  },
];
