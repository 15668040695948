<template>
  <validation-provider v-slot="{ errors }" :vid="vid" :name="name" :rules="rules" :mode="mode">
    <t-form-element>
      <slot v-if="!noLabel" name="label">
        <t-form-label>
          <!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -->
          {{ label ? label : name }} <span v-if="required">*</span> <slot name="help" />
        </t-form-label>
      </slot>
      <slot />
      <slot name="error" :errors="errors">
        <span v-if="errors" class="text--danger">{{ errors[0] }}</span>
      </slot>
    </t-form-element>
  </validation-provider>
</template>
<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    vid: {
      type: String,
      required: false,
      default: null,
    },

    name: {
      type: String,
      required: true,
    },

    label: {
      type: String,
      required: false,
      default: '',
    },

    rules: {
      type: [Object, String],
      required: false,
      default: null,
    },

    noLabel: {
      type: Boolean,
      required: false,
      default: false,
    },

    mode: {
      type: String,
      required: false,
      default: 'eager',
    },
  },

  computed: {
    required() {
      if (Array.isArray(this.rules)) {
        return this.rules.includes('required');
      }

      if (typeof this.rules === 'string' || this.rules instanceof String) {
        return this.rules.split('|').includes('required');
      }

      return false;
    },
  },
});
</script>
