import { RuleParamSchema } from 'vee-validate/dist/types/types';
import { timeParse } from '~/utils';

const validate = (value: string, { start }: Record<string, any> = {}) => {
  return !start || timeParse(value).isAfter(timeParse(start));
};

const params: RuleParamSchema[] = [
  {
    name: 'start',
    isTarget: true,
  },
];

const message = '{_field_} muss nach {start} sein.';

export default {
  validate,
  params,
  message,
};
