import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';
import onboarding from '~/routes/applicant/onboarding';

export default () => [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: RouteEnum.DASHBOARD,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'applicant:dashboard' */ '~/pages/applicant/dashboard/show.vue'),
      ),
  },
  ...onboarding,
  {
    path: '/near-by',
    name: RouteEnum.NEAR_BY,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'applicant:near-by' */ '~/pages/applicant/near-by.vue'),
      ),
  },
  {
    path: '/help',
    name: RouteEnum.HELP,
    component: () =>
      interopDefault(import(/* webpackChunkName: 'applicant:help' */ '~/pages/applicant/help.vue')),
  },
];
