import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';

export default [
  {
    path: '/tenants',
    name: RouteEnum.TENANTS.INDEX,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'admin:tenants.index' */ '~/pages/admin/tenants/index.vue'),
      ),
  },
  {
    path: '/tenants/:id',
    name: RouteEnum.TENANTS.SHOW,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'admin:tenants.show' */ '~/pages/admin/tenants/show.vue'),
      ),
  },
];
