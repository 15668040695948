import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';
import organizations from '~/routes/tenantmember/organizations';
import employees from '~/routes/tenantmember/employees';
import applicants from '~/routes/tenantmember/applicants';

export default () => [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: RouteEnum.DASHBOARD,
    component: () =>
      interopDefault(
        import(
          /* webpackChunkName: 'tenantmember:dashboard' */ '~/pages/tenantmember/dashboard/show.vue'
        ),
      ),
  },
  ...applicants,
  ...organizations,
  ...employees,
  {
    path: '/help',
    name: RouteEnum.HELP,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'tenantmember:help' */ '~/pages/tenantmember/help.vue'),
      ),
  },
];
