import { Route } from '@stafftastic/vue-router';
import { omitBy } from 'lodash-es';
import * as qs from 'qs';
import debounce from '~/utils/debounce';
import zip from '~/utils/zip';
import { findLast } from '~/utils/findLast';
import { format as timeFormat, parse as timeParse } from '~/utils/time';
// @ts-ignore

const interopDefault = (promise: Promise<any>) => promise.then(m => m.default || m);

const groupBy = (arr: any[], byKey: string | Function) => {
  const groups: Record<any, any> = {};

  arr.forEach((item) => {
    const normalizedByKey = typeof byKey === 'string' ? byKey : byKey();
    const keyValue = item[normalizedByKey];
    if (groups[keyValue] === undefined) {
      groups[keyValue] = [];
    }

    groups[keyValue].push(item);
  });

  return groups;
};

const unique = (arr: any[]) => {
  return arr.filter((value: any, index: number, arr: any[]) => {
    return arr.indexOf(value) === index;
  });
};

function hexToRgb(hex: string): { r: number; g: number; b: number } | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const capitalizeFirst = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

const updateItemInArray = (array: any[], item: any, key = 'id') => {
  const index = array.findIndex(s => s[key] === item[key]);
  const updatedItem = {
    ...array[index],
    ...item,
  };
  array.splice(index, 1, updatedItem);
};

const removeItemInArray = (array: any[], id: any) => {
  return array.filter(c => c.id !== id);
};

const commaSeparatedListFromArray = (array: any[], key: any) => {
  return array.map(item => item[key]).join(', ');
};

const getTokenQuery = (from: Route) => {
  // Pass on the invitation code.
  if (!from || !from.query?.token) {
    return {};
  }
  return {
    token: from.query.token,
  };
};

const guessInvitationToken = (url: string) => {
  if (!url.includes('?')) { return url; }
  const queryString = url.split('?')[1];
  const parsed = qs.parse(queryString);

  return parsed.code || parsed.token || null;
};

const filterObject = (object: object) => {
  return omitBy(object, (value) => {
    return !value;
  });
};

export {
  debounce,
  timeFormat,
  timeParse,
  interopDefault,
  findLast,
  groupBy,
  unique,
  hexToRgb,
  capitalizeFirst,
  updateItemInArray,
  removeItemInArray,
  commaSeparatedListFromArray,
  getTokenQuery,
  zip,
  filterObject,
  guessInvitationToken,
};
