import { browserProfilingIntegration, browserTracingIntegration, captureException, init, replayIntegration } from '@sentry/vue';
import Vue from 'vue';
export default defineNuxtPlugin(function (_ref) {
  var hook = _ref.hook;
  var router = useRouter();
  var _useRuntimeConfig$pub = useRuntimeConfig().public.sentry,
    dsn = _useRuntimeConfig$pub.dsn,
    environment = _useRuntimeConfig$pub.environment,
    tracesSampleRate = _useRuntimeConfig$pub.traces_sample_rate,
    profilesSampleRate = _useRuntimeConfig$pub.profiles_sample_rate,
    replaysSessionSampleRate = _useRuntimeConfig$pub.replays_session_sample_rate,
    replaysOnErrorSampleRate = _useRuntimeConfig$pub.replays_on_error_sample_rate;
  init({
    Vue: Vue,
    dsn: dsn,
    environment: environment,
    release: process.env.SENTRY_RELEASE,
    integrations: [browserTracingIntegration({
      router: router,
      routeLabel: 'path'
    }), browserProfilingIntegration(), replayIntegration()],
    trackComponents: true,
    tracesSampleRate: tracesSampleRate,
    profilesSampleRate: profilesSampleRate,
    replaysSessionSampleRate: replaysSessionSampleRate,
    replaysOnErrorSampleRate: replaysOnErrorSampleRate
  });
  hook('app:error', function (err) {
    captureException(err);
  });
});