import Model from '~/models/Model';
import RouteEnum from '~/enums/RouteEnum';
import buildOnboardingFlow from '~/pages/applicant/flows/onboarding/buildOnboardingFlow';

export default class Applicant extends Model {
  resource() {
    return 'v1/applicants';
  }

  hasOnboarded() {
    const flow = buildOnboardingFlow();

    return flow.completed({
      applicant: this,
    });
  }

  getOnboardingRoute() {
    return RouteEnum.APPLICANT_ONBOARDING;
  }

  async statistics() {
    // @ts-ignore
    const endpoint = this.endpoint();

    const response = await this.request({
      method: 'GET',
      url: `${endpoint}/_statistics`,
    });

    return response?.data;
  }

  static statistics() {
    const self = new this();

    return self.statistics();
  }
}
