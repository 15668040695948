<template>
  <TLoading v-if="loading" :centered="false" />
  <t-select
    v-else
    v-model="computedValue"
    :options="resources"
    :get-option-label="getOptionLabel"
    :reduce="reduce"
    :multiple="multiple"
    :no-options-message="noOptionsMessage"
    v-bind="$attrs"
  />
</template>

<script>
import Vue from 'vue';
import TLoading from '~/components/TLoading';

export default Vue.extend({
  components: {
    TLoading,
  },

  props: {
    value: {
      validator: prop =>
        typeof prop === 'string' || Array.isArray(prop) || prop === null || prop === undefined,

      required: true,
    },

    getOptionLabel: {
      type: Function,
      required: false,
      default: resource => resource.translation,
    },

    reduce: {
      type: Function,
      required: false,
      default: resource => resource.id,
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selected: [],
      resources: [],

      loading: true,
    };
  },

  computed: {
    computedValue: {
      get() {
        return this.selected;
      },

      set(value) {
        let val = value;

        if (!this.multiple && Array.isArray(value) && value.length === 0) {
          val = null;
        }

        this.selected = val;
        this.$emit('input', val);
        this.$emit('change', val);
      },
    },

    noOptionsMessage() {
      return this.$t('components.t_select.no_matching_options');
    },
  },

  watch: {
    value(value) {
      this.selected = value;
    },

    selected(value) {
      if ((Array.isArray(value) && value.length === 0) || !value) {
        this.$emit('original', null);
        return;
      }

      const index = this.resources.findIndex((element) => {
        return this.reduce(element) === value;
      });
      this.$emit('original', this.resources[index]);
    },
  },

  async created() {
    try {
      this.resources = await this.fetch();

      if (this.resources.length === 0) {
        this.$emit('empty');
      }

      this.selected = this.value;
      this.loading = false;
    } catch (e) {
      this.$axios.handleError(e);
    }
  },

  methods: {
    fetch() {
      // abstract.
    },
  },
});
</script>
