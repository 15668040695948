import { interopDefault } from '~/utils';
import RouteEnum from '~/enums/RouteEnum';
import applicants from '~/routes/admin/applicants';
import organizations from '~/routes/admin/organizations';
import tenants from '~/routes/admin/tenants';
import employees from '~/routes/admin/employees';

export default () => [
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: RouteEnum.DASHBOARD,
    component: () =>
      interopDefault(
        import(/* webpackChunkName: 'admin:dashboard' */ '~/pages/admin/dashboard/show.vue'),
      ),
  },
  ...applicants,
  ...organizations,
  ...tenants,
  ...employees,
  {
    path: '/help',
    name: RouteEnum.HELP,
    component: () =>
      interopDefault(import(/* webpackChunkName: 'admin:help' */ '~/pages/admin/help.vue')),
  },
];
