// @ts-ignore
import UsageTypeStep from './Steps/UsageType/UsageTypeStep';
// @ts-ignore
import AboutYouStep from './Steps/AboutYou/AboutYouStep';
import Step from '~/components/Flows/Build/Step';
import Flow from '~/components/Flows/Build/Flow';

export default (): Flow => {
  const usageTypeStep = new Step({
    id: 'usage-type',
    slug: 'usage-type',
    component: UsageTypeStep,
    props: ['organization'],
    image: 'login',

    isComplete: ({ organization }: { organization: any }): boolean => {
      return !!organization?.usage_type;
    },
  });

  const aboutYouStep = new Step({
    id: 'about-you',
    slug: 'about-you',
    component: AboutYouStep,
    props: ['employee'],
    image: 'login',

    isComplete: ({ employee }: { employee: any }): boolean => {
      return !!employee?.meta?.phonenumber;
    },
  });

  return new Flow([usageTypeStep, aboutYouStep]);
};
