<script>
import Vue from 'vue';
import TValidationWrapper from '~/components/TValidationWrapper.vue';
import AbstractMethodError from '~/errors/AbstractMethodError';

export default Vue.extend({
  components: {
    TValidationWrapper,
  },

  data: () => ({
    form: null,
    loading: false,
  }),

  methods: {
    getForm(_entity = null) {
      throw new AbstractMethodError();
    },

    async submit() {
      this.loading = true;
      try {
        const entity = await this.request(this.getPayload());
        this.$emit('saved', entity);
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
        return false;
      } finally {
        this.loading = false;
      }
    },

    request(_payload) {
      throw new AbstractMethodError();
    },

    getPayload() {
      return { ...this.form };
    },
  },
});
</script>
