import { Ability } from '@casl/ability';

export default new Ability([
  {
    action: 'create',
    subject: 'Alert',
  },
  {
    action: 'create',
    subject: 'Profile',
  },
  {
    action: 'create',
    subject: 'RegistrationCode',
  },
  {
    action: 'create',
    subject: 'EmailTemplate',
  },
]);
