import getAbilitiesForUserType from '~/abilities/getAbilitiesForUserType';
var caslPlugin = function caslPlugin(_ref, inject) {
  var store = _ref.store;
  var ability = getAbilitiesForUserType(store.getters['auth/userType']);
  inject('ability', ability);
  store.subscribe(function (mutation, state) {
    var _state$auth, _state$auth$me, _state$auth$me$user;
    var userType = (_state$auth = state.auth) === null || _state$auth === void 0 ? void 0 : (_state$auth$me = _state$auth.me) === null || _state$auth$me === void 0 ? void 0 : (_state$auth$me$user = _state$auth$me.user) === null || _state$auth$me$user === void 0 ? void 0 : _state$auth$me$user.type;
    if (mutation.type === 'auth/SET_ME' || mutation.type === 'auth/PATCH_ME') {
      ability.update(getAbilitiesForUserType(userType).rules);
    }
  });
};
export default caslPlugin;