import { RouteConfig } from '@nuxt/types/config/router';
import applicantRoutes from '~/routes/applicant';
import employeeRoutes from '~/routes/employee';
import tenantMemberRoutes from '~/routes/tenantmember';
import adminRoutes from '~/routes/admin';
import sharedRoutes from '~/routes/shared';
import UserTypeEnum from '~/enums/UserTypeEnum';

export default (userType: string): RouteConfig[] => {
  if (userType === UserTypeEnum.APPLICANT) {
    return [...applicantRoutes(), ...sharedRoutes()];
  }

  if (userType === UserTypeEnum.EMPLOYEE) {
    return [...employeeRoutes(), ...sharedRoutes()];
  }

  if (userType === UserTypeEnum.TENANT_MEMBER) {
    return [...tenantMemberRoutes(), ...sharedRoutes()];
  }

  if (userType === UserTypeEnum.ADMIN) {
    return [...adminRoutes(), ...sharedRoutes()];
  }

  return [];
};
