import libPhoneNumber from 'libphonenumber-js';

// @ts-ignore
const validate = (value) => {
  const number = libPhoneNumber(value);
  if (number) {
    return number.isValid();
  }
  return false;
};

const message = '{_field_} ist keine gültige Telefonnummer.';

export default {
  validate,
  message,
};
