import { RuleParamSchema } from 'vee-validate/dist/types/types';
import dayjs from '~/libraries/dayjs';

const validate = (value: string, { minAge }: Record<string, any> = {}) => {
  return dayjs().diff(dayjs(value), 'year') >= minAge;
};

const params: RuleParamSchema[] = [
  {
    name: 'minAge',
  },
];

const message = '{_field_} muss älter sein als {minAge} Jahre.';

export default {
  validate,
  params,
  message,
};
