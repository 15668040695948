<template>
  <t-split-screen centered>
    <div v-if="error.statusCode === 404">
      <h1>{{ $t('pages.error.404.heading') }}</h1>
      <h3>
        {{ $t('pages.error.404.lead') }}
      </h3>
    </div>
    <div v-else>
      <h1>{{ $t('pages.error.unexpected.heading') }}</h1>
      <h3>
        {{ $t('pages.error.unexpected.lead') }}
      </h3>
      <p class="text--grey">
        <strong>{{ $t('pages.error.error_message', { message: error.message }) }}</strong>
      </p>
    </div>

    <p class="text--grey">
      <strong>{{ $t('pages.error.error_code', { errorCode: error.statusCode }) }}</strong>
    </p>

    <p>{{ $t('pages.error.helpful_links') }}</p>
    <dl>
      <dd>
        <nuxt-link :to="{ name: $RouteEnum.LOGIN }">
          {{ $t('global.actions.login') }}
        </nuxt-link>
      </dd>
      <dd>
        <nuxt-link :to="{ name: $RouteEnum.FORGOT_PASSWORD }">
          {{ $t('pages.login.actions.forgot_password') }}
        </nuxt-link>
      </dd>
    </dl>

    <dashboard-image slot="secondary" name="error" />
  </t-split-screen>
</template>

<script>
import DashboardImage from '~/components/DashboardImage.vue';

export default {
  components: {
    DashboardImage,
  },

  layout: 'standalone',

  props: {
    error: {
      type: Object,
      required: true,
    },
  },
};
</script>
