<template>
  <div class="flow-step-footer">
    <div class="flow-step-footer__left">
      <slot name="left">
        <t-button v-if="step.prev" type="plain" @click="$emit('prev')">
          {{ $t('global.actions.back') }}
        </t-button>
        <t-button v-if="step.isOptional" soft @click="$emit('skip')">
          {{ $t('global.actions.skip') }}
        </t-button>
      </slot>
    </div>
    <div class="flow-step-footer__right">
      <slot />
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import Step from './Build/Step';

export default Vue.extend({
  props: {
    step: {
      type: Step,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.flow-step-footer {
  margin-top: $s-16;
  display: flex;
  justify-content: space-between;

  &__left {
    button {
      margin-right: $s-2;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
