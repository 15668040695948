<template>
  <t-navigation class="navigation">
    <router-link slot="branding" :to="{ name: $RouteEnum.DASHBOARD }">
      <navigation-branding />
    </router-link>

    <t-navigation-item
      v-for="item in items"
      :key="item.routeName"
      :icon="item.icon"
      tag="router-link"
      :to="{ name: item.routeName }"
      active-class="t-navigation-item__link--active"
    >
      {{ item.label }}
    </t-navigation-item>

    <t-navigation-item
      slot="drawer"
      icon="sign-out-alt"
      tag="router-link"
      :to="{ name: $RouteEnum.LOGOUT }"
      active-class="t-navigation-item__link--active"
    >
      {{ $t('global.actions.logout') }}
    </t-navigation-item>
  </t-navigation>
</template>

<script>
import Vue from 'vue';
import NavigationBranding from '~/layouts/partials/NavigationBranding.vue';

export default Vue.extend({
  components: {
    NavigationBranding,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },
});
</script>
<style lang="scss" scoped>
.navigation {
  flex-shrink: 0;
}
</style>
