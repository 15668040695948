<template>
  <t-select
    v-model="computedValue"
    :options="resources"
    :get-option-label="getOptionLabel"
    :get-option-key="getOptionKey"
    :reduce="reduce"
    :multiple="multiple"
    v-bind="$attrs"
  >
    <template #selected-option="{ option }">
      {{ $t(getOptionLabel(option)) }}
    </template>
    <template #option="{ option }">
      {{ $t(getOptionLabel(option)) }}
    </template>
  </t-select>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: [Array, Number, String, Boolean],
      required: false,
      default: null,
    },

    getOptionLabel: {
      type: Function,
      required: false,
      default: resource => resource.labelKey,
    },

    getOptionKey: {
      type: Function,
      required: false,
      default: resource => resource.key,
    },

    reduce: {
      type: Function,
      required: false,
      default: resource => resource.key,
    },

    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      selected: this.value ?? [],
      resources: [],
    };
  },

  computed: {
    computedValue: {
      get() {
        return this.selected;
      },

      set(value) {
        this.selected = value;
        this.$emit('input', value);
        this.$emit('change', value);
      },
    },
  },

  watch: {
    value(value) {
      this.selected = value;
    },
  },
});
</script>
